var wipwrp = window.wipwrp || {};

wipwrp.commons = (function ($, mzr, doc, win) {
	/* ----------------- private vars ---------------*/

	var self = {};

	function _userAgentCheck() {
		function showUAModal() {
			var popup = document.querySelector('#browser-popup');
			var wrap = document.querySelector('#wrap');
			var main = document.querySelector('#main');
			popup.style.display = 'block';
			if (wrap) wrap.style.paddingBottom = '40px';
			if (main) main.style.paddingBottom = '40px';
		}

		function matchVersion(userAgent, regex, minVersion) {
			if ( (typeof userAgent) != 'string') {
				return false;
			}
			try {
				var groups = userAgent.matchAll(regex);
				var val = groups.next().value;
				if (!val) return false;
				return Number(val[1]) >= minVersion;
			} catch (e) {
				return false;
			}
		}

		var validAgents = [
			[/Chrome\/(\d{1,})/g, 99],
			[/Firefox\/(\d{1,})/g, 98],
			[/Edg\/(\d{1,})/g, 99],
			[/Version\/(\d{1,}).\d{0,} Safari\/\d{0,}/g, 15], // Safari desktop
			[/Version\/(\d{1,}).\d{0,} Mobile\/[A-Za-z\d]{0,} Safari\/\d{0,}/g, 15], // Safari mobile
			[/CriOS\/(\d{1,}).\d{0,} Mobile\/[A-Za-z\d]{0,} Safari\/\d{0,}/g, 99], // Chrome on iOS
			[/FxiOS\/(\d{1,}).\d{0,} Mobile\/[A-Za-z\d]{0,} Safari\/\d{0,}/g, 98] // Firefox on iOS
		];

		console.log(navigator.userAgent)
		var isValid = false;
		for (var i = 0; i < validAgents.length; i++) {
			var matches = matchVersion(navigator.userAgent, validAgents[i][0], validAgents[i][1])
			if (matches) {
				isValid = true;
				break;
			}
		}

		if (!isValid) {
			showUAModal();
		}
	}

	function _syncContractSwitch() {
		var url = window.location;

		var IDCON = $('.contract-switch').data('IDCON');
		var IDM = $('.contract-switch').data('IDM');
		var piattaformaDigitale = url.pathname.endsWith('/piattaforma_digitale');

		var val = url.protocol + '://' + url.host;
		if (piattaformaDigitale) val += '/piattaforma_digitale'
		if (!IDCON && IDM) val += '/fiera?IDM=' + IDM
		if (IDCON) val += '/fiera?IDCON=' + IDCON
		$('.contract-switch').val(val)
	}
	/* ----------------- public methods ---------------*/

	self.init = function () {
		$("html").removeClass("no-document-ready").addClass("document-ready");
		wipwrp.utils.tooltip();
		wipwrp.utils.toggleSystem();
		wipwrp.utils.checkMenu("#masthead");
		$(".blk-gn-select select").customSelect();
		setInterval(function () {
			$.ajax({
				type: "GET",
				url: "nop.php",
			});
		}, 60000);

		self.trimNewLine();
		self.handleNavTabs();
		_userAgentCheck();
		_syncContractSwitch();
		window.addEventListener('pageshow', _syncContractSwitch)
	};

	self.setGraficoAcconto = function () {
		var _tot = $("#graficoacconto_wrap").data("totale");
		var _part = $("#graficoacconto_wrap").data("pagato");
		if (_tot == "") {
			_tot = 0;
		}
		if (_part == "") {
			_part = 0;
		}

		if (_tot > 0) {
			if (parseInt(_part, 10) < parseInt(_tot, 10)) {
				var roundValue = Math.round((parseInt(_part, 10) * 100) / _tot);
				$("#graficoacconto_pagato").css("width", roundValue + "%");
				if (parseInt(_part, 10) > 4) {
					$("#graficoacconto_pagato").html("<span>" + roundValue + "% </span>");
				}
			} else {
				$("#graficoacconto_pagato").css("width", "100%");
			}
		}
	};

	self.initRepeater = function () {
		$(".repeater").repeater({
			isFirstItemUndeletable: true,
		});
	};

	self.moneynumber = function (target) {
		$(target).each(function () {
			var obj = {};
			var _arr, _int, _dec;
			obj.$input = $(this);

			if (obj.$input.val() !== "") {
				// mi saspetto un numero normale, tipo 11111.111
				obj.n = parseFloat(obj.$input.val(), 10);
				obj.n = obj.n.toFixed(2);
				obj.s = obj.n.toString().replace(".", ",");
				_arr = obj.s.split(",");
				_int = _arr[0];
				_dec = _arr[1];
				obj.s = Number(_int).toLocaleString() + "," + _dec;
			} else if (obj.$input.attr("required") === undefined) {
				obj.n = 0.0;
				obj.s = "0,00";
			} else {
				obj.n = null;
				obj.s = "";
			}

			obj.$input.val(obj.s);

			var _valName = obj.$input.attr("name");
			obj.$input.removeAttr("name");
			obj.$truevalue = $(
				'<input type="hidden" name="' +
					_valName +
					'" id="' +
					_valName +
					'_id" />'
			);
			obj.$input.parent().append(obj.$truevalue);
			obj.$truevalue.val(obj.n);

			obj.$input.on("blur", function () {
				//se è cambiato qualcosa
				if (obj.$input.val() !== obj.s) {
					if (obj.$input.val().match(/[a-z]/i)) {
						obj.s = "00,00";
						obj.$input.val(obj.s);
					}
					//elimino eventuali lettere e special chars
					obj.s = obj.$input.val().replace(/[^0-9$.,]/g, "");
					if (obj.s) {
						//se non c'è la virgola
						if (obj.s.indexOf(",") < 0) {
							if (obj.s.indexOf(".") < 0) {
								obj.s = obj.s + ",00";
							} else {
								obj.s = obj.s.replace(".", ",");
							}
						}
						//splitto il numero tra intero e decimali
						_arr = obj.s.split(",");
						_int = _arr[0];
						//se l'intero non c'è
						if (_int === "") {
							_int = "0";
						}
						_int = _int.replace(/\./g, "");
						_int = parseInt(_int, 10);

						_dec = _arr[1];
						_dec = _dec.substring(0, 2);

						obj.n = parseFloat(_int + "." + _dec, 10);
						obj.$truevalue.val(obj.n).change();
						obj.s = Number(_int).toLocaleString() + "," + _dec;
						obj.$input.val(obj.s);
					}
				}
			});
		});
	};

	self.returnmoneynumber = function (num) {
		var obj = {};
		var _arr, _int, _dec;
		if (num != "") {
			// mi saspetto un numero normale, tipo 11111.111
			obj.n = parseFloat(num, 10);
			obj.n = obj.n.toFixed(2);
			obj.s = obj.n.toString().replace(".", ",");
			_arr = obj.s.split(",");
			_int = _arr[0];
			_dec = _arr[1];
			obj.s = Number(_int).toLocaleString() + "," + _dec;
		} else {
			obj.n = 0.0;
			obj.s = "0,00";
		}

		return obj.s;
	};

	self.negativemoneynumber = function (target) {
		$(target).each(function () {
			var obj = {};
			var _arr, _int, _dec;
			obj.$input = $(this);
			if (obj.$input.val() !== "") {
				// mi saspetto un numero normale, tipo 11111.111
				obj.n = parseFloat(obj.$input.val(), 10);
				obj.n = obj.n.toFixed(2);
				obj.s = obj.n.toString().replace(".", ",");
				_arr = obj.s.split(",");
				_int = _arr[0];
				_dec = _arr[1];
				obj.s = Number(_int).toLocaleString() + "," + _dec;
			} else {
				obj.n = 0.0;
				obj.s = "0,00";
			}

			obj.$input.val(obj.s);

			var _valName = obj.$input.attr("name");
			obj.$input.removeAttr("name");
			obj.$truevalue = $(
				'<input type="hidden" name="' +
					_valName +
					'" id="' +
					_valName +
					'_id" />'
			);
			obj.$input.parent().append(obj.$truevalue);
			obj.$truevalue.val(obj.n);

			obj.$input.on("blur", function () {
				//se è cambiato qualcosa
				if (obj.$input.val() != obj.s) {
					//elimino eventuali lettere e special chars
					obj.s = obj.$input.val().replace(/[^0-9$.,-]/g, "");
					//se non c'è la virgola
					if (obj.s.indexOf(",") == -1) {
						if (obj.s.indexOf(".") == -1) {
							obj.s = obj.s + ",00";
						} else {
							obj.s = obj.s.replace(".", ",");
						}
					}
					//splitto il numero tra intero e decimali
					_arr = obj.s.split(",");
					_int = _arr[0];
					//se l'intero non c'è
					if (_int === "") {
						_int = "0";
					}
					_int = _int.replace(/\./g, "");
					_int = parseInt(_int, 10);

					_dec = _arr[1];
					_dec = _dec.substring(0, 2);

					obj.n = parseFloat(_int + "." + _dec, 10);
					obj.$truevalue.val(obj.n).change();
					obj.s = Number(_int).toLocaleString() + "," + _dec;
					obj.$input.val(obj.s);
				}
			});
		});
	};

	self.checkCapByNation = function () {
		/**
		 * fa un controllo sul campo nazione associato. riferisce al name:
		 * è necassario riferirsi al name perché è un campo hidden generato dal plugin dell'autocomplete -> xxx_value
		 */
		$("[data-relatednationname]").each(function () {
			var $capField = $(this);
			var $relNation = $(
				'[name="' + $capField.data("relatednationname") + '"]'
			);
			var $label = $capField.parent(".control-group").find("label");
			$relNation.on("change", function () {
				if ($relNation.val() === "ITA") {
					// ECCEZIONE: CAP é SEMPRE OBBLIGATORIO SE ITA TRANNE IN CATALOGO
					if ($capField.attr("id") != "cap_azienda_catalogo") {
						if ($label.html().indexOf("<span>*</span>") == -1) {
							$label.append("<span>*</span>");
						}
						$capField.attr("required", "true");
					}
					$capField.attr("data-parsley-cap", "true");
				} else {
					$label = $capField.parent(".control-group").find("label");
					var _t = $label.html();
					$label.text(_t.replace("<span>*</span>", ""));
					$capField.removeAttr("data-parsley-cap").removeAttr("required");
				}
			});
			$relNation.trigger("change");
		});
	};

	self.copyOnOut = function () {
		var _target, _value;
		$("[data-copythisthere]").on("blur", function () {
			_target = $(this).attr("data-copythisthere");
			_value = $(this).val();
			if ($(_target).val() === "") {
				$(_target).val(_value).change().trigger("keyup");
			}
		});
	};

	self.copyGroup = function () {
		var _destination, _target, _value;

		$(".copy-group").on("click", function () {
			_destination = $(this).attr("data-copydestination");
			$(_destination + " [data-copytarget]").each(function () {
				_target = $(this).attr("data-copytarget");
				_value = $(_target).val();
				$(this).val(_value).change().trigger("keyup");
			});
		});
	};

	self.relationField = function () {
		/**
		 * testato su select e radio
		 * select -> [data-relation="NOME_GRUPPO"]
		 * contenuto da mostrare/nascondere: [data-relationgroup="NOME_GRUPPO"][data-related="OPTION_SELECTED"]
		 * apr.2016 - update: posso aggiungere più valori data-related, separati da spazio semplice
		 * febb.2018 - update: in caso non sia mostrare/nascondere, ma nascondere/mostrare: [data-relationgroup="NOME_GRUPPO"][data-related="OPTION_SELECTED"][data-relatedinverse]
		 */
		var _group, _target;
		//modifica senza il change finale
		$("[ data-relationgroup]").hide();

		$("body").on("change", "[data-relation]", function () {
			_group = $(this).data("relation");
			_target = $(this).val();
			// standard case: show related
			$(
				'[data-relationgroup="' +
					_group +
					'"]:not([data-related~="' +
					_target +
					'"]):not([data-relatedinverse])'
			)
				.hide()
				.addClass("novalidate");
			$(
				'[data-relationgroup="' +
					_group +
					'"][data-related~="' +
					_target +
					'"]:not([data-relatedinverse])'
			)
				.show()
				.removeClass("novalidate");
			// inverse case: hide related
			$(
				'[data-relationgroup="' +
					_group +
					'"][data-relatedinverse]:not([data-related~="' +
					_target +
					'"])'
			)
				.show()
				.removeClass("novalidate");
			$(
				'[data-relationgroup="' +
					_group +
					'"][data-relatedinverse][data-related~="' +
					_target +
					'"]'
			)
				.hide()
				.addClass("novalidate");
		});

		$("[data-relation]").each(function () {
			_group = $(this).data("relation");
			_target = $(this).val();
			// standard case: show related
			$(
				'[data-relationgroup="' +
					_group +
					'"]:not([data-related*="' +
					_target +
					'"]):not([data-relatedinverse])'
			)
				.hide()
				.addClass("novalidate");
			$(
				'[data-relationgroup="' +
					_group +
					'"][data-related*="' +
					_target +
					'"]:not([data-relatedinverse])'
			)
				.show()
				.removeClass("novalidate");
			// inverse case: hide related
			$(
				'[data-relationgroup="' +
					_group +
					'"][data-relatedinverse]:not([data-related*="' +
					_target +
					'"])'
			)
				.show()
				.removeClass("novalidate");
			$(
				'[data-relationgroup="' +
					_group +
					'"][data-relatedinverse][data-related*="' +
					_target +
					'"]'
			)
				.hide()
				.addClass("novalidate");
		});
	};

	self.setInputParent = function (parentSelector) {
		// controllo i filled all'inizio
		$(parentSelector + " input, " + parentSelector + " textarea").each(
			function () {
				if ($(this).val().length > 0) {
					$(this).parents(parentSelector).addClass("valued");
				}
			}
		);

		//filled e focus su input e textarea
		$(parentSelector + " input, " + parentSelector + " textarea")
			.on("focus", function () {
				$(this).parents(parentSelector).addClass("focused");
			})
			.on("blur", function () {
				$(parentSelector).removeClass("focused");
			});
		$(parentSelector + " input, " + parentSelector + " textarea").on(
			"change",
			function () {
				if ($(this).val().length > 0) {
					$(this).parents(parentSelector).addClass("valued");
				} else {
					$(this).parents(parentSelector).removeClass("valued");
				}
			}
		);

		// Add attribute data-limit to checkbox-group-wrap
		// to limit checkbox change
		$(".checkbox-group-wrap").each(function (i, el) {
			var limit = Number($(this).attr("data-limit"));
			var activeElements = $(el).find("li.active").length;
			var $notification = $(el).find(".notification");
			$notification.slideUp();

			$(el).on("change", 'input[type="checkbox"]', function () {
				if ($(this).is(":checked")) {
					if (limit && activeElements + 1 > limit) {
						$(this).removeAttr("checked");
						$notification.slideDown();
					} else {
						activeElements += 1;
						$(this).parents("li").addClass("active");
						$notification.slideUp();
					}
				} else {
					activeElements -= 1;
					$(this).parents("li").removeClass("active");
					$notification.slideUp();
				}
			});
		});
	};

	self.datepicker = function () {
		if ($(".datepicker").length > 0) {
			jQuery(".datepicker").pickadate({
				format: "dd/mm/yyyy",
				selectMonths: true,
				selectYears: "120",
				min: new Date(1900, 1, 1),
				max: true, // to today
			});
		}
	}; // _datepicker();

	self.trimNewLine = function () {
		$(".no-line-break").on("blur", function () {
			// console.log(this)
			var trim = $(this).val().replace(/\n/g, ", ");
			$(this).val(trim);
		});
	};

	self.handleNavTabs = function () {
		var tabs = document.querySelectorAll(".tab-item");
		var sections = document.querySelectorAll(".tab-content");

		tabs.forEach((tab) => {
			tab.addEventListener("click", (e) => {
				e.preventDefault();
				// check if current page is "domanda" or "aflive", otherwise addActiveTab will override the modal triggered by _openAbandonAlert in domanda.js
				var currentPage = document.querySelector('body');
				var pageAttribute = currentPage.getAttribute('data-page');
				if (pageAttribute === 'domanda' || pageAttribute === 'aflive') {
					return;
				} else {
					removeActiveTab();
					addActiveTab(tab);
				}
			});
		});

		var removeActiveTab = function () {
			tabs.forEach((tab) => {
				tab.classList.remove("active");
			});
			sections.forEach((section) => {
				section.classList.remove("is-active");
			});
		};

      var addActiveTab = (tab) => {
          tab.classList.add("active");
          window.location = tab.dataset.slug;
          /* if you prefer to switch tab without redirecting the page: */
          // var sectionId = tab.dataset.section;
          // var matchingSection = $("#" + sectionId);
          // matchingSection.addClass("is-active");
      };
	};

	self.initCkEditor = function (uniqueSelector, options) {
		var el = document.querySelector( uniqueSelector );
		var config = options ? options : {
			toolbar: {
				items: [
					'bold',
					'|',
					'heading'
				],
				tooltip: true
			},
            heading: {
                options: [
                    { model: 'heading', view: 'h2', title: 'Titolazione' },
                    { model: 'paragraph', view: 'p', title: 'Paragrafo' }
                ]
            }
		}
		if(!el) return;
		ClassicEditor
        .create( el , config )
		.then( newEditor => {
			/* forzato update in focus per casistiche di controlli a step parsley */
			newEditor.ui.focusTracker.on( 'change:isFocused', function(evt){
				newEditor.updateSourceElement();
			});
		})
        .catch( error => {
            console.error( error );
        } );
	};

	/* ----------------- return it public ---------------*/

	return self;
})(jQuery, Modernizr, document, window);
